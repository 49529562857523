import React from 'react';
import { ConfigProvider, theme } from 'antd';
import RouteF from './RouteF';
import { BrowserRouter } from 'react-router-dom';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { Provider } from 'react-redux';
import { store } from './shared'
import 'react-big-calendar/lib/css/react-big-calendar.css'

function App() {
  return (
    <div>
      <BrowserRouter>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#D0162C',
              borderRadius:2,
              borderrad2: 8,
              borderrad3: 5,
              borderCardrad:20,
              borderCardcolor: '#fff',
              cardshadow:'0px 4px 25px 0px #D9D9D9',
              mainColor: '#D0162C',
              secondarColor:'#001529',
              colorgray: '#3E4854',
              bgtransparent:'transparent',
              border2:'#D9D9D9',
              fonth:'13px',
              colorBgdefault:'#ECEDEE',
              colordef:'#A8AAAE'
            }
          }}
        >
          <Provider  store={store}>
            <RouteF theme={theme.components} />
          </Provider>
        </ConfigProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
